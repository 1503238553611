<template>
  <v-container align="center" fluid class="d-flex justify-center mb-15">
    <v-row class="d-flex justify-center">
      <v-col cols="12" class="d-flex justify-center pb-0" >
        <v-card :loading="loading" width="850" elevation="3" id="dataapp">
          <!-- <v-row>
        <v-col cols="10"></v-col>
        <v-specer></v-specer>
        <v-col cols="2">
          <v-btn text @click="print">
            <v-icon class="pr-5" large color="black">mdi-printer </v-icon>
          </v-btn>
        </v-col>
      </v-row> -->

          <v-card-title class="ml-10 mt-5"> </v-card-title>

          <v-card-text>
            <v-row class="ml-10" style="border-bottom: 1px groove">
              <v-col cols="9" class="mt-7">
                <v-row class="d-flex flex-column">
                    <v-col class="pt-0">
                        <v-text style="font-size:2.1rem ; color:#757575;"> 
                            INVOICE
                        </v-text>
                         <p class="mb-0 text-h-1">INVOICE</p> 
                    </v-col>
                  <v-col class="py-0"
                    ><p class="font-weight-medium py-0 mb-0">
                      BILL FROM :
                    </p></v-col
                  >
                  <v-col class="pb-0"
                    ><p class="mb-0 text-body-1">
                      {{ companyinfo.name }}
                    </p></v-col
                  >
                  <v-col cols="4" class="py-0">
                    <p class="text-body-1 mb-0">{{ companyinfo.address }}</p>
                  </v-col>
                </v-row>
              </v-col>

               <v-col cols="2" align-self="center">
                <div style="height: 70%; width: 70%">
                  <v-img
                    
                    :src="companyinfo.logo_url"
                    alt="Rooster crowing"
                  ></v-img>
                </div>
              </v-col>
            </v-row>
            <div>
              <!-- <v-row class="ml-10 mt-10 d-flex-row">
            <v-col cols="3">
              <p class="font-weight-medium">Invoice Number:</p>
              <p>{{ invoiceinfo.id }}</p>
            </v-col>

            <v-col cols="3">
              <p class="font-weight-medium">Payment status:</p>
              <p>{{ orderinfo.order_status }}</p>
            </v-col>
            <v-col cols="3">
              <p class="font-weight-medium">Date of Issue:</p>
              <p>{{ invoiceinfo.date }}</p>
            </v-col>

            <v-col cols="3">
              <p class="font-weight-medium">Time:</p>
              <p>{{ invoiceinfo.time }}</p>
            </v-col>
          </v-row> -->

              <v-row class="ml-10 pa-0 mt-5">
                <v-col class="py-0 mb-0" cols="8"
                  ><p class="mb-0 font-weight-medium">BILL TO :</p></v-col
                >
                <v-col cols="4" class="py-0 d-flex flex-row">
                  <p class="text-body-1 mb-0">INVOICE #</p>
                  <v-spacer></v-spacer>
                  <p class="text-body-1 mb-0 mr-13">{{ invoiceinfo.id }}</p>
                </v-col>

                <v-col cols="8" class="py-0">
                  <p class="text-body-1 mb-0 mt-2">
                    {{ userdata.name }}
                  </p>
                </v-col>
                <v-col cols="4" class="py-0 d-flex flex-row">
                  <v-col class="py-0 d-flex flex-row pl-0 mr-5">
                    <p class="text-body-1 mb-0">Date:</p>
                    <v-spacer></v-spacer>
                    <p class="text-body-1 mb-0 mr-5">{{ invoiceinfo.date }}</p>
                  </v-col>
                </v-col>

                <!-- <v-col cols="2" class="py-0">
              <p class="text-body-1"></p>
            </v-col> -->

                <v-col cols="8" class="py-0 ">
                  <p class="text-body-1 mb-0">
                    {{ userdata.phone_number }}
                  </p>
                  <p class="text-body-1 mb-0">{{userdata.address}}</p>
                  <p class="text-body-1 mb-0">{{userdata.location}}</p>
                </v-col>
                <v-col
                  cols="4"
                  v-if="orderinfo.order_status == 'Unpaid'"
                  class="py-0"
                  dense
                >
                  <div
                    class="d-flex flex-row align-center mr-12"
                    style="background-color: #cfd3d4"
                    
                  >
                    <p class="mt-2 mb-1 font-weight-medium ml-2 mb-2">
                      Amount Due:
                    </p>
                    <v-spacer></v-spacer>
                    <p class="mt-2 mb-1 font-weight-medium mr-5">
                      {{ orderinfo.price_total }}
                    </p>
                  </div>
                   <div>
                    <p
                      style="font-family: 'PT Serif', serif; bold; font-size:30px;"
                      class="mt-3 mb-2 font-weight-bold"
                    >
                      UNPAID
                    </p>
                  </div>
                </v-col>
                 <v-col
                  cols="4"
                  v-if="orderinfo.order_status == 'Paid'"
                  class="py-0"
                >
                  <p
                    style="font-family: 'PT Serif', serif; font-size: 30px"
                    class="mt-2 mb-0 font-weight-bold"
                  >
                    PAID
                  </p>
                </v-col>

                <!-- <v-col cols="3" class="py-0">
              <p class="font-weight-small text-body-1 mb-0">City</p>
            </v-col>
            <v-col cols="9" class="py-0">
              <p class="text-body-1 mb-0">: N/A</p>
            </v-col>
            <v-col cols="3" class="py-0">
              <p class="font-weight-small text-body-1 mb-0">Phone Number</p>
            </v-col>
            <v-col cols="9" class="py-0">
              <p class="text-body-1 mb-0">: N/A</p>
            </v-col> -->
              </v-row>
              <v-row> </v-row>
              <v-card min-height="500" elevation="0" class="mt-2">
                <v-simple-table class="mx-12 mb-5" dense>
                  <template v-slot:default>
                    <thead>
                      <tr class="blue-grey lighten-4">
                        <th class="text-body-2">Name</th>
                        <th class="text-body-2">Color</th>
                        <th class="text-body-2">Size</th>
                        <th class="text-body-2">Quantity</th>
                        <th class="text-body-2">Unit</th>
                        <th class="text-body-2">Unit Price</th>
                        <!-- <th class="text-body-2">Discount</th> -->
                        <th class="text-body-2">Amount</th>
                        <th class="text-body-2">Discount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in orders"
                        :key="item.product_name"
                        align-center
                      >
                        <td class="">
                          <p class="mb-0" >
                            {{ item.product_name }}
                          </p>
                       
                        </td>
                        <td class=" ">
                          <p class="mb-0">
                            {{ item.product_color }}
                          </p>
                      
                        </td>
                        <td class="">
                          <p class="mb-0">
                            {{ item.product_size }}
                          </p>
                      
                        </td>
                          <td class="">{{ item.quantity }}</td>
                        <td class="">
                          <p class="mb-0">
                            {{ item.product_unit }}
                          </p>
                     
                        </td>

                      
                        <td class="">{{ item.unit_price }}</td>
                        <!-- <td class="">{{ item.total_discount }}</td> -->
                        <td class="">{{ item.total_price }}</td>
                        <td class="">{{ item.product_discount }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <!-- <v-data-table
                dense
                :headers="headers"
                :items="orders"
                class="elevation-0 mx-12"
                hide-default-footer
              >
                <template slot="items" slot-scope="props">
                  <td>{{ props.item.product_name }}</td>
                  <td class="text-xs-right">{{ props.item.product_color }}</td>
                  <td class="text-xs-right">{{ props.item.product_size }}</td>
                  <td class="text-xs-right">{{ props.item.product_unit }}</td>
                  <td class="text-xs-right">{{ props.item.total_quantity }}</td>
                  <td class="text-xs-right">{{ props.item.unit_price }}</td>
                  <td class="text-xs-right">{{ props.item.total_discount }}</td>
                  <td class="text-xs-right">{{ props.item.total_price }}</td>
                </template>
              </v-data-table> -->

                <v-row>
                  <v-col cols="7"> </v-col>

                  <v-col cols="4" class="pr-0">
                    <div>
                      <div
                        class="d-flex flex-row align-center pr-5 mb-1"
                        style="background-color: #cfd3d4"
                      >
                        <p class="mb-1 text-body-1 ml-2">Sub Total</p>
                        <v-spacer></v-spacer>
                        <p class="mb-1 text-body-1 mr-0 mb-0">
                          {{ orderinfo.sub_price }}
                        </p>
                      </div>
                      <div
                        class="d-flex flex-row align-center pr-5 mb-1"
                        style="background-color: #cfd3d4"
                      >
                        <p class="text-body-1 ml-2 mb-0">Discount</p>
                        <v-spacer></v-spacer>
                        <p class="mr-0 text-body-1 mb-0">
                          {{ orderinfo.overall_discount }}
                        </p>
                      </div>

                      <div
                        class="d-flex flex-row align-center pr-5 mb-1"
                        style="background-color: #cfd3d4"
                      >
                        <p class="text-body-1 ml-2 mb-0">VAT</p>
                        <v-spacer></v-spacer>
                        <p class="mr-0 text-body-1 mb-0">
                          {{ orderinfo.vat }}
                        </p>
                      </div>

                      <!-- <div class="d-flex flex-row align-center pr-5 mb-1" 
                      style="background-color: #cfd3d4"
                      >
                        <p class="text-body-1 ml-2 mt-2">VAT</p>
                        <v-spacer></v-spacer>
                        <p class="text-body-1 mr-0 mt-2">
                          {{ orderinfo.vat }}
                        </p>
                      </div> -->
                      <div
                        class="d-flex flex-row align-center pr-5"
                        style="background-color: #cfd3d4"
                      >
                        <p class="mt-2 mb-1 font-weight-medium ml-2 mb-2">
                          Grand Total
                        </p>
                        <v-spacer></v-spacer>
                        <p class="mt-2 mb-1 font-weight-medium mr-0">
                          {{ orderinfo.price_total }}
                        </p>
                      </div>
                    </div>
                  </v-col>
                </v-row>

                <v-card v-if="orderinfo.order_status == 'Paid'" elevation="0">
                  <!-- <v-simple-table class="mx-12 my-5" dense>
              <template v-slot:default>
                <thead>
                  <tr class="blue-grey lighten-4">
                    <th class="text-body-2">Transaction Date</th>
                    <th class="text-body-2">Gateway</th>
                    <th class="text-body-2">Transaction ID</th>
                    <th class="text-body-2">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in orderinfo" :key="item.id" align-center>
                    <td class="">
                      <p class="mb-0" v-if="item.ordered_date">
                        {{ item.ordered_date }}
                      </p>
                      N/A
                    </td>
                    <td class=" ">
                     
                      N/A
                    </td>
                    <td class="">
                    
                      N/A
                    </td>
                    <td class="">
                      <p v-if="item.product_unit">{{ item.total_price }}</p>
                      N/A
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table> -->
                  <div>
                    <div style="border: 1px groove" class="mx-12">
                      <v-row>
                        <v-row class="ml-5">
                          <v-col class="" cols="3"> Transaction Date </v-col>
                          <v-col cols="3"> GateWay</v-col>
                          <v-col cols="3"> Transaction ID </v-col>
                          <v-col cols="3"> Amount</v-col>
                        </v-row>
                      </v-row>
                    </div>
                    <div style="border: 1px groove" class="mx-12">
                      <v-row>
                        <v-row class="ml-5">
                          <v-col class="" cols="3">
                            <p class="text-body-1  mb-0" v-if="orderinfo.ordered_date">
                              {{ orderinfo.ordered_date }}
                            </p>
                          </v-col>
                          <v-col cols="3" class="text-body-1  mb-0"> N/A</v-col>
                          <v-col cols="3" class="text-body-1  mb-0"> N/A </v-col>
                          <v-col cols="3">
                            <p v-if="orderinfo.grand_total" class="text-body-1  mb-0" >
                              {{ orderinfo.grand_total }}
                            </p>
                          </v-col>
                        </v-row>
                      </v-row>
                    </div>
                  </div>
                </v-card>
              </v-card>

              <v-row class="ml-10 d-flex flex-row">
                <v-col cols="12">
                  <p class="font-weight-medium"></p>
                  <p class="text-caption">
                    This Invoice Is Autogenareted By
                    {{ companyinfo.name }} After Confirming Your Order And
                    Payment
                  </p>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="d-flex justify-center py-0">
        <v-card elevation="0" class="mt-0">
          <v-btn text @click="printData" class="pa-0" style="padding-left:5px!important; ">
            <v-icon class="pr-1" large color="black">mdi-printer </v-icon>
            Print
          </v-btn>
           <v-btn text @click="printData" style="padding-left:0px!important; padding-right:10px!important;">
            <v-icon class="pr-0" large color="black">mdi-download </v-icon>
            download
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
       


<script>
export default {
  data: () => ({
    invoiceinfo: {},
    orders: [],
    orderinfo: {},
    bill: [],
    specifications: [],
    companyinfo: {},
    snackbar: "",
    invoicearray: [],
    userdata: {},
    // headers: [
    //   {
    //     text: "Name",
    //     align: "start",
    //     sortable: false,
    //     value: "product_name",
    //     class: "blue darken-1 text-body-2 white--text",
    //   },
    //   {
    //     text: "Color",
    //     value: "product_color",
    //     sortable: false,
    //     class: "blue darken-1 text-body-2 white--text",
    //   },
    //   {
    //     text: "Size",
    //     value: "product_size",
    //     sortable: false,
    //     class: "blue darken-1 text-body-2 white--text",
    //   },
    //   {
    //     text: " Unit",
    //     value: "product_unit",
    //     sortable: false,
    //     class: "blue darken-1 text-body-2 white--text",
    //   },

    //   {
    //     text: "Quantity ",
    //     value: "total_quantity",
    //     sortable: false,
    //     class: "blue darken-1 text-body-2 white--text",
    //   },
    //   {
    //     text: "Unit Price ",
    //     value: "unit_price",
    //     sortable: false,
    //     class: "blue darken-1 text-body-2 white--text",
    //   },
    //   {
    //     text: "Discount ",
    //     value: "total_discount",
    //     sortable: false,
    //     class: "blue darken-1 text-body-2 white--text",
    //   },
    //   {
    //     text: "Amount",
    //     value: "total_price",
    //     sortable: false,
    //     class: "blue darken-1 text-body-2 white--text",
    //   },
    // ],
  }),
  methods: {
    initialize(item) {
      axios
        .get(`Cart/create_invoice/${this.$route.query.id}/`)
        .then((response) => {
          this.invoiceinfo = response.data.invoice_data;
          this.orderinfo = response.data.order_data;
          this.orders = response.data.order_data.orders;
          this.companyinfo = response.data.company_data;
         this.userdata = response.data.billing_address_data;
        });
    },

    //   printData() {
    // //   var divToPrint = document.getElementById("dataapp");
    // //   var style = "<style>";
    // //   style = style + "table {width: 100%;font: 17px Calibri;}";
    // //   style =
    // //     style +
    // //     "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
    // //   style = style + "padding: 2px 3px;text-align: center;}";
    // //   style = style + "</style>";

    // //   var win = window.open("", "", "height=1900,width=1900");
    // //   win.document.write(style); //  add the style.
    // //   win.document.write(divToPrint.outerHTML);
    // //   win.document.close();
    // //   win.print();
    // window.print();
    // },

    // printData() {
    //   var printContents = document.getElementById("dataapp").innerHTML;
    //   var originalContents = document.body.innerHTML;

    //   document.body.innerHTML = printContents;
    //   window.print();

    //   document.body.innerHTML = originalContents;
    // },
    printData() {
      //this.$htmlToPaper("dataapp");
      this.$htmlToPaper("dataapp");
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped>
/* tbody tr:nth-of-type(even) {
  background-color: #e3f2fd !important;
} */
/* .v-data-table > .v-data-table__wrapper > table > tbody > tr :nth-child(even){
 
  background-color: #ff7743;
 
}
table.v-table thead tr{
 max-height:1px !important;
} */
/* 
table.v-table thead tr:nth-child(even) {
  background-color: #dddddd;
} */
/* .invoice{
  background: #f5f5f5; 
  font-size: 10em
}
.invoice .theme--light.v-table{
  background-color: #00f !important;
} */
</style>